<template>
    <v-row justify="center" class="py-5" align="center" style="height: 100%">
        <v-col style="text-align: center" cols="12" sm="6">
            <v-form @submit.prevent="search" @keyup.enter.native.prevent="search">
            <p>
                Are you trying to unsubscribe or change your email preferences?
            </p>
            <p>
                Search for a brand:
            </p>
            <v-text-field v-model="searchQuery" ref="searchInput" dense solo color="teal">
                <template #prepend-inner>
                    <font-awesome-icon :icon="['fas', 'search']" fixed-width/> <!-- style="font-size: 20px;" -->
                </template>
            </v-text-field>
            <v-btn @click="search" elevation="4" color="teal white--text">Search</v-btn>
            </v-form>
            <!-- TODO: move all these "Powered by Emplus" footers to an actual footer maybe at the bottom of the page -->
            <!-- <p class="mt-12"><a :href="mainWebsiteURL">Powered by Emplus</a></p> -->
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
export default {
    data: () => ({
        searchQuery: null,
        searchActionTimestamp: null,
    }),
    computed: {
        mainWebsiteURL() {
            return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://emplus.io';
        },
        brandprofileSearchURL() {
            return process.env.VUE_APP_BRANDPROFILE_SEARCH_URL ?? 'https://cdn.brandprofile.org/search';
        },
    },
    methods: {
        search() {
            if (Number.isInteger(this.searchActionTimestamp) && this.searchActionTimestamp + 500 > Date.now()) {
                return;
            }
            this.searchActionTimestamp = Date.now();
            const searchURL = new URL(this.brandprofileSearchURL);
            const searchParams = new URLSearchParams();
            searchParams.append('q', this.searchQuery);
            searchParams.append('intent', 'unsubscribe');
            searchURL.search = searchParams.toString();
            const redirect = searchURL.toString();
            window.location.href = redirect;
        },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => { this.activate('searchInput'); }, 1);
        });
    },
};
</script>
